//@ts-nocheck
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";
import { getSlug } from "utils/helper";

//Common
import Layout from "modules/common/Layout/index";
import Fallback from "modules/common/Pages/fallback";
//Junte e Troque

// // import PromoJunteTroque from "modules/promocaoJunteTroque/Pages/promoJunteTroque";
// import MyPromos from "modules/promocaoJunteTroque/Pages/myPromos";
// import MyShells from "modules/promocaoJunteTroque/Pages/myShells";
// import MyRescuesJunteTroque from "modules/promocaoJunteTroque/Pages/myRescues";

//Hub
import ShellboxBenefits from "modules/hub/Pages/ShellboxBenefits";
import Offers from "modules/hub/Pages/offers";
import OffersDetail from "modules/hub/Pages/OffersDetail";
import RoadPromotions from "modules/hub/Pages/RoadPromotions";
import PromoRimula2023 from "modules/promoRimula2023/pages";

//Menos15
import HubHome from "modules/hub/Pages/HubHome";

// Promo Junte Troque ano todo
import HomeAnoTodo from "modules/promoAnoTodo/pages/home";
import ComoFunciona from "modules/promoAnoTodo/pages/comoFunciona";
import PostosParticipantes from "modules/promoAnoTodo/pages/postosParticipantes";
import CadastroAnoTodo from "modules/promoAnoTodo/pages/cadastro";
import HelpAnoTodo from "modules/promoAnoTodo/pages/duvidas";
import RulesAnoTodo from "modules/promoAnoTodo/pages/regulamento";
import RecuperarSenhaJTAnoTodo from "modules/promoAnoTodo/pages/recuperarSenha";
import OilPromotions from "modules/hub/Lubrificantes/OilPromotions";

// Promo Grande GP São paulo
import { LayoutGp } from "modules/promoGrandeGp/layout";
import HomeGp from "modules/promoGrandeGp/pages/Home/index";
import NumerosDaSorteGp from "modules/promoGrandeGp/pages/NumerosDaSorte";
import GanhadoresGp from "modules/promoGrandeGp/pages/Ganhadores";
import FaleConoscoGp from "modules/promoGrandeGp/pages/FaleConosco";
import PostosParticipantesGp from "modules/promoGrandeGp/pages/PostosParticipantes";
import DuvidasGp from "modules/promoGrandeGp/pages/Duvidas";
import CadastroGp from "modules/promoGrandeGp/pages/Cadastro";
import RulesGp from "modules/promoGrandeGp/pages/Regulamento";
import EsqueceuSenhaGp from "modules/promoGrandeGp/pages/EsqueceuSenha";
import ResgateDeIngressosGp from "modules/promoGrandeGp/pages/ResgateDeIngressos";

// Promo GP 2024
// import { LayoutGp2024 } from "modules/promoGrandeGP2024/layout";
// import HomeGP2024 from "modules/promoGrandeGP2024/pages/Home";
// import GanhadoresGp2024 from "modules/promoGrandeGP2024/pages/Ganhadores";
// import ResgateDeIngressosGp2024 from "modules/promoGrandeGP2024/pages/ResgateDeIngressos";
// import PostosParticipantesGp2024 from "modules/promoGrandeGP2024/pages/PostosParticipantes";
// import RulesGp2024 from "modules/promoGrandeGP2024/pages/Regulamento";
// import DuvidasGp2024 from "modules/promoGrandeGP2024/pages/Duvidas";

// Hub
const HubRoutes = (slug) => (
  <Switch>
    <Route exact path={`/`} component={HubHome} />
    <Route exact path={`/ofertas-shell-select`} component={Offers} />
    <Route
      exact
      path={`/ofertas-shell-select/detalhe`}
      component={OffersDetail}
    />
    <Route exact path={`/promocoes-de-rodovia`} component={RoadPromotions} />
    <Route
      exact
      path={`/beneficios-app-shell-box`}
      component={() =>
        window.location.assign("https://promo.shell.com.br/shellbox/")
      }
    />
  </Switch>
);

// Promo Junte Troque Ano Todo
const PromoAnoTodo = (slug) => (
  <Switch>
    <Route exact path={`/${slug}`}>
      <Redirect to={`/${slug}/todos-produtos`} component={HomeAnoTodo} />
    </Route>
    <Route
      exact
      path={`/${slug}/esqueceu-a-senha`}
      component={RecuperarSenhaJTAnoTodo}
    />
    <Route exact path={`/${slug}/todos-produtos`} component={HomeAnoTodo} />
    <Route exact path={`/${slug}/como-funciona`} component={ComoFunciona} />
    <Route
      exact
      path={`/${slug}/postos-participantes`}
      component={PostosParticipantes}
    />
    <Route exact path={`/${slug}/cadastro`} component={CadastroAnoTodo} />
    <Route exact path={`/${slug}/duvidas`} component={HelpAnoTodo} />
    <Route exact path={`/${slug}/regulamento`} component={RulesAnoTodo} />

    <Switch>
      <LayoutGp>
        {/* <Route exact path={`/${slug}/gp-sp-2023`} component={HomeGp} />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/numeros-da-sorte`}
          component={NumerosDaSorteGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/resgate-de-ingressos`}
          component={ResgateDeIngressosGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/ganhadores`}
          component={GanhadoresGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/postos-participantes`}
          component={PostosParticipantesGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/regulamento`}
          component={RulesGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/duvidas`}
          component={DuvidasGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/cadastro`}
          component={CadastroGp}
        />
        <Route
          exact
          path={`/${slug}/gp-sp-2023/esqueceu-a-senha`}
          component={EsqueceuSenhaGp}
        /> */}
      </LayoutGp>
    </Switch>
  </Switch>
);

// Promo Grande GP 2024
// const PromoGp2024 = (slug) => (
//   <LayoutGp2024>
//     <Switch>
//       <Route exact path={`/${slug}`} component={HomeGP2024} />
//       <Route exact path={`/${slug}/ganhadores`} component={GanhadoresGp2024} />
//       <Route
//         exact
//         path={`/${slug}/resgate-de-ingressos`}
//         component={ResgateDeIngressosGp2024}
//       />
//       <Route
//         exact
//         path={`/${slug}/postos-participantes`}
//         component={PostosParticipantesGp2024}
//       />
//       <Route exact path={`/${slug}/regulamento`} component={RulesGp2024} />
//       <Route exact path={`/${slug}/duvidas`} component={DuvidasGp2024} />
//     </Switch>
//   </LayoutGp2024>
// );

//Rotas
const Routes = ({ history }) => (
  <Router history={history}>
    <Layout>
      <Switch>
        <Route
          path="/junte-troque"
          // component={() => PromoAnoTodo("junte-troque")}
        >
          <Redirect to={`/`} />
        </Route>

        <Route
          path={`/gp-sp-2024`}
          // component={() => PromoGp2024("gp-sp-2024")}
        >
          <Redirect to={`/`} />
        </Route>

        <Route exact path={`/gp-sp-2023`}>
          {/* <Redirect to={`/junte-troque/gp-sp-2023`} /> */}
          <Redirect to={`/`} />
        </Route>

        <Route
          exact
          path={`/lubrificantes`}
          component={() =>
            window.location.replace(`http://feriasparaoseucarro.com.br/`)
          }
        />

        <Route
          exact
          path={`/lubrificantes/promocaoshellhelix`}
          component={() =>
            window.location.assign(`https://promocaoshellhelix.com.br/`)
          }
        />

        <Route
          path={"/campanhas-de-lubrificantes"}
          component={() => <PromoRimula2023 />}
        />

        <Route
          exact
          // path="/"
          component={() => HubRoutes()}
        />

        <Route
          exact
          path="/nao-foi-possivel-carregar-conteudo"
          component={Fallback}
        />
        <Redirect
          from="/"
          to={window.location.search.includes("shellbox") ? getSlug().url : "/"}
        />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
